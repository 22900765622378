<template>
<div class="baseInfo_content"  v-if="0">
   <Row>      
         <vcol span="8" offset="1"  class="baseInfo" ><span class="custom-title">姓名：{{baseInfo.nickName}}</span> </vcol>
         <vcol span="7"  class="baseInfo" ><span class="custom-title">姓别：{{baseInfo.sex}}</span> </vcol>
         <vcol span="6" class="baseInfo"  ><span class="custom-title">年龄：{{baseInfo.age}}</span> </vcol>
    </Row>
</div>
</template>

<script>
import {  Row,Col as vcol  } from 'vant';
export default {
  name:'BaseInfo',
  components:{
    Row,vcol
  },
  props:{
    baseInfo:{
     type: Object,
     required:true
    }
  },
  data() {
    return {
      
    }
  },
  created(){
    
  }
  ,
  methods: {
     
  },
  watch:{
        
    },
   mounted() {//在模板编译完成后执行
          
    },
  computed:{
   
  }
}
</script>

<style  scoped>
.baseInfo{
  color:black;
  font-size: small;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  
}
.baseInfo_content{

  background-color: #e0e0e0;
}
.custom-title{
  margin-left: 1em;
}
</style>