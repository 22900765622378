import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import hmpackages from './modules/hmpackages'
Vue.use(Vuex)


const store = new Vuex.Store({
  modules: {
    user,hmpackages
  }
})

export default store
