import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { Toast,Lazyload ,NavBar,Divider } from 'vant';

import '@/assets/css/weui.css';
import  {parseTime,getQueryString,formatMoney,builtMap} from './utils/commons';
import BaseInfo from '@/components/baseInfo';
import RLFooter from '@/components/footer';
import * as RongIMLib from '@rongcloud/imlib-v4'
import Chat from 'vue-beautiful-chat'
import LemonIMUI from 'lemon-imui';
import 'lemon-imui/dist/index.css';
import settings from './settings.js'

Vue.config.productionTip = false
Vue.prototype.parseTime = parseTime;
Vue.prototype.getQueryString = getQueryString;
Vue.prototype.formatMoney = formatMoney;
Vue.prototype.builtMap = builtMap;
Vue.prototype.settings = settings

Vue.use(Toast);
Vue.use(LemonIMUI);
Vue.use(Chat)

Vue.use(Lazyload,{
  lazyComponent: true
});
Vue.prototype.Toast=Toast;
Vue.component('Toast',Toast );
Vue.component('NavBar', NavBar);
Vue.component('Divider',Divider );
Vue.component('BaseInfo',BaseInfo);
Vue.component('RLFooter',RLFooter);
Vue.component('Rongyun',RongIMLib);

import Router from 'vue-router'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const crfRuleEventBus = new Vue()
Vue.prototype.$ruleBus = crfRuleEventBus

//组件传值用
Vue.prototype.$Event=new Vue();

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
