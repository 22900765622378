const hmpackages = {
  state: {
    packageMap:{}
  },
  mutations: {
    updatePackages:function(state,newmsg){
      state.packageMap=newmsg
    },
   
  },
  actions:{
    updatePackages:function(context){
      context.commit('updatePackages');
    },
  }
}

export default hmpackages
