<template>
<div class="weui-form__extra-area">
		<div class="weui-footer  weui-footer_fixed-bottom">
			<p class="weui-footer__links">
				<a href="javascript:" class="weui-footer__link">北京瑞林萨尔科技有限公司</a>
			</p>
			<!-- <p class="weui-footer__text">Copyright © 2008-2020 RILINTECH</p> -->
		</div>
 </div>
</template>

<script>
export default {
  name:'RLFooter',
  components:{
    
  },
  props:{
   
  },
  data() {
    return {
      
    }
  },
  created(){
    
  }
  ,
  methods: {
     
  },
  watch:{
        
    },
   mounted() {//在模板编译完成后执行
          
    },
  computed:{
   
  }
}
</script>

<style  scoped>

</style>