const user = {
  state: {
    openid:process.env.NODE_ENV=='development'?process.env.VUE_APP_TEST_WXID:'',
    pid:process.env.NODE_ENV=='development'?process.env.VUE_APP_TEST_PID:undefined,
    checkMap:{},
    baseInfo:{
      nickName:process.env.NODE_ENV=='development'?'henrya8':undefined,
      sex:process.env.NODE_ENV=='development'?'男':undefined,
      age:process.env.NODE_ENV=='development'?18:undefined
    }
  },
  mutations: {
    updateOpenid:function(state,newmsg){
      state.openid=newmsg
    },
    updatePid:function(state,newmsg){
      state.pid=newmsg
    }, updateCheckMap:function(state,ncheckMap){
      state.checkMap=ncheckMap
    }, updateBaseInfo:function(state,nbaseInfo){
      state.baseInfo=nbaseInfo;
    }
  },
  actions:{
    updateOpenid:function(context){
      context.commit('updateOpenid');
    },
    updatePid:function(context){
      context.commit('updatePid');
    },
    updateCheckMap:function(context){
      context.commit('updateCheckMap');
    },
    updateBaseInfo:function(context){
      context.commit('updateBaseInfo');
    }
  }
}

export default user
