import Vue from 'vue'
import Router from 'vue-router'
//import MainGrid from '@/view/MainGrid'
Vue.use(Router)



/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

// 公共路由
export const constantRoutes = [
  { path: '', component:  (resolve) => require(['@/views/maingrid'], resolve) } ,
   { path: '/report', component:  (resolve) => require(['@/views/report'], resolve) },
   { path: '/tjreport', component:  (resolve) => require(['@/views/tjreport'], resolve) },
   { path: '/tjdata', component:  (resolve) => require(['@/views/tjdata'], resolve) },
   { path: '/records', component:  (resolve) => require(['@/views/records'], resolve) },
   { path: '/queidx/:pid', component:  (resolve) => require(['@/views/queidx'], resolve) },
   { path: '/que/:mid', component:  (resolve) => require(['@/views/que'], resolve) },
   { path: '/fillbaseinfopage', component:  (resolve) => require(['@/views/baseinfo'], resolve) }   ,
   // { path: '/pateintinfo', component:  (resolve) => require(['@/views/patient/info'], resolve) }   ,
   { path: '/doctorinfo', component:  (resolve) => require(['@/views/doctor/info'], resolve) }   ,
   { path: '/registerDoctor', component:  (resolve) => require(['@/views/doctor/registerInfo'], resolve) }   ,
   // { path: '/followup', component:  (resolve) => require(['@/views/follow/followcrfs'], resolve) }   ,

   { path: '/jiankang', component:  (resolve) => require(['@/views/jiankang/info'], resolve) }   ,
   { path: '/jianjie', component:  (resolve) => require(['@/views/jianjie/info'], resolve) }   ,
   { path: '/tochat/:userid', component:  (resolve) => require(['@/views/tochat/info'], resolve) }   ,
   { path: '/tochatdoctors', component:  (resolve) => require(['@/views/tochat/doctors'], resolve) }   ,
   { path: '/tochatmessages', component:  (resolve) => require(['@/views/tochat/info'], resolve) }   ,
   { path: '/crf/:crfid', component:  (resolve) => require(['@/views/follow/showcrf'], resolve) }   ,
   { path: '/rehabilitation', component:  (resolve) => require(['@/views/rehabilitation/info'], resolve) }   ,
   { path: '/rehabilitationInfo', component:  (resolve) => require(['@/views/rehabilitation/rehabilitationInfo'], resolve) }   ,
   { path: '/pushque/:pid', component:  (resolve) => require(['@/views/pushque'], resolve) } ,
   { path: '/err', component:  (resolve) => require(['@/views/common/faile'], resolve) } ,
   { path: '/success', component:  (resolve) => require(['@/views/common/success'], resolve) },
   // { path: '/upload', component:  (resolve) => require(['@/views/upload'], resolve) } ,
   { path: '/reportidx', component:  (resolve) => require(['@/views/reportidx'], resolve) } ,
   { path: '/healthmanage/packageidx', component:  (resolve) => require(['@/views/healthmanage/packageidx'], resolve) } ,
   { path: '/healthmanage/packagedetail/:id', component:  (resolve) => require(['@/views/healthmanage/packagedetail'], resolve) } ,
   { path: '/healthmanage/orderlist', component:  (resolve) => require(['@/views/healthmanage/orderlist'], resolve) } ,
   { path: '/healthmanage/orderdetail/:ordnum', component:  (resolve) => require(['@/views/healthmanage/orderdetail'], resolve) } ,
   { path: '/healthmanage/mypackages', component:  (resolve) => require(['@/views/healthmanage/mypackages'], resolve) } ,
   //套餐详情图片   
   { path: '/healthmanage/packagedetailimg', component:  (resolve) => require(['@/views/healthmanage/packagedetailimg'], resolve) }   ,
//基本信息
 { path: '/pateintinfo', component:  (resolve) => require(['@/views/patient/info'], resolve) }   ,
//注册文卷-测试
 { path: '/register', component:  (resolve) => require(['@/views/follow/showcrf'], resolve) }   ,
//随访问卷列表
 { path: '/followup', component:  (resolve) => require(['@/views/follow/followcrfs'], resolve) }   ,
//随访问卷
 { path: '/followcrf', component:  (resolve) => require(['@/views/follow/showcrf'], resolve) }   ,
 { path: '/weixin/examinations', component:  (resolve) => require(['@/views/follow/showcrf'], resolve) }   ,
 //随访问卷-app推送
 { path: '/weixin/crf-follow', component:  (resolve) => require(['@/views/follow/showsendcrf'], resolve) }   ,
 //   推送健康方案列表_全部
 { path: '/jkgl_types', component:  (resolve) => require(['@/views/jkgl/info_types'], resolve) }   ,
 //   推送健康方案列表_某类型
 { path: '/jkgl_type', component:  (resolve) => require(['@/views/jkgl/info_exetype'], resolve) }   ,
 //   推送健康方案列表_推送
 { path: '/jkgl', component:  (resolve) => require(['@/views/jkgl/info'], resolve) }   ,
//健康方案
 { path: '/exe/:id', component:  (resolve) => require(['@/views/jkgl/showexe'], resolve) }   ,
//健康方案
 { path: '/weixin/exercise', component:  (resolve) => require(['@/views/jkgl/showexercise'], resolve) }   ,
//上传图片/视频
 { path: '/toUploadFile', component:  (resolve) => require(['@/views/upload/toUploadFile.vue'], resolve) }   ,
// 影像视频
 { path: '/upload', component:  (resolve) => require(['@/views/upload/info'], resolve) }   ,
 // 视频详情
 { path: '/videoInfo', component:  (resolve) => require(['@/views/upload/videoInfo'], resolve) }   ,
 // 我的医生
 { path: '/doctors', component:  (resolve) => require(['@/views/doctor/doctors'], resolve) }   ,
//健康日记列表
 { path: '/diary', component:  (resolve) => require(['@/views/crfs/diarys'], resolve) }   ,
//评估问卷列表
 { path: '/crfs', component:  (resolve) => require(['@/views/crfs/crfs'], resolve) }   ,
//问卷详情
 { path: '/crf', component:  (resolve) => require(['@/views/crfs/showcrf'], resolve) }   ,
//体检指标
 { path: '/tjzb', component:  (resolve) => require(['@/views/tjzb/tjzb'], resolve) }   ,
//体检指标-关注设置
 { path: '/tjzb_attention', component:  (resolve) => require(['@/views/tjzb/tjzb_attention'], resolve) }   ,
//体检指标-提醒设置
 { path: '/tjzb_task', component:  (resolve) => require(['@/views/tjzb/tjzb_task'], resolve) }   ,
//问题反馈
 { path: '/support', component:  (resolve) => require(['@/views/support/support'], resolve) }   ,
//动作评估列表
 { path: '/identify', component:  (resolve) => require(['@/views/identify/identify'], resolve) }   ,
//动作评估-上传图片/视频
 { path: '/upload_identify', component:  (resolve) => require(['@/views/identify/uploadIdentify'], resolve) }   ,
//动作评估-评估报告
 { path: '/identify_report', component:  (resolve) => require(['@/views/identify/identify_report'], resolve) }   ,
//常见问题
 { path: '/faq', component:  (resolve) => require(['@/views/faq/faq'], resolve) }   ,
//训练器材
 { path: '/store', component:  (resolve) => require(['@/views/store/store'], resolve) }   ,
//未填问卷列表
 { path: '/weixin/send-crf-list-empty', component:  (resolve) => require(['@/views/crfs/crfs_empty'], resolve) }   ,
 { path: '/mettingVideo', component:  (resolve) => require(['@/views/metting/index'], resolve) }   ,
 { path: '/mettingvideoInfo', component:  (resolve) => require(['@/views/metting/mettingvideoInfo'], resolve) }   ,

]

export default new Router({
  mode:'hash' ,// 'hash' 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
})
